<script setup lang="ts">
import type { HTMLProps } from "../types-utils";
const props = withDefaults(
	defineProps<
		{
			modelValue?: boolean;
			color?: string;
			leftLabel?: boolean;
			label?: string;
			size?: number;
			trackOpacity?: number;
			explicitColor?: boolean;
		} & HTMLProps
	>(),
	{
		modelValue: false,
		trackOpacity: 0.2,
		color: "var(--changzhou)",
		leftLabel: false,
		label: "",
		size: 20
	}
);
defineEmits(["update:modelValue"]);

const size = computed(() => `${props.size}px`);
</script>

<template>
	<label class="toggle" :class="{ reverse: leftLabel, 'explicit-color': explicitColor }">
		<input
			:checked="modelValue"
			type="checkbox"
			class="input"
			@click="$emit('update:modelValue', ($event.target as HTMLInputElement).checked)"
		/>
		<div class="inner" :class="{ truthy: modelValue }">
			<div class="track" />

			<slot name="thumb" className="thumb-slot">
				<div class="thumb" />
			</slot>
		</div>
		<span v-if="label" class="label">
			{{ label }}
			<slot name="label-icon" />
		</span>
	</label>
</template>

<style lang="scss">
:root {
	--a-toggle-track-background-color: var(--conakry);
	--a-toggle-thumb-background-color: var(--conakry);
}

.tao:root {
	--a-toggle-track-background-color: var(--cucuta);
	--a-toggle-thumb-background-color: var(--culiacan);
}

.funzCity:root {
	--a-toggle-track-background-color: var(--chitungwiza);
	--a-toggle-thumb-background-color: var(--chifeng);
}
@if mixin-exists(AToggle) {
	@include AToggle;
}
</style>
<style scoped lang="scss">
$thumb-transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);

input {
	display: none;
}

.toggle {
	cursor: pointer;
	display: flex;
	align-items: center;

	&.reverse {
		flex-direction: row-reverse;

		.label {
			margin-left: 0;
			margin-right: gutter(1.5);
		}
	}

	&.explicit-color .track {
		transition: none;
		background-color: v-bind(color);
	}
}

.inner {
	position: relative;
	width: calc(v-bind("size") * 3);
	height: calc(v-bind("size") * 1.4);

	&.truthy {
		color: v-bind(color);

		.track {
			background: currentColor;
		}

		.thumb {
			left: calc(100% - v-bind("size") - 4px);

			&::after {
				background: currentColor;
			}
		}

		:deep(.thumb-slot) {
			left: calc((v-bind("size") * 3) + 1px);
			transform: translate(-100%, -50%);
		}
	}
}

.label {
	margin-left: gutter(1.5);
}

.track {
	height: 100%;
	background-color: var(--a-toggle-track-background-color);
	border-radius: calc(v-bind("size") * 0.7);
	transition: 0.4s;
	opacity: v-bind("trackOpacity");
}

.thumb {
	position: absolute;
	width: v-bind(size);
	height: v-bind(size);
	top: 50%;
	left: 4px;
	transform: translateY(-50%);
	transition: left $thumb-transition;

	&::after {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		border-radius: 50%;
		background: var(--a-toggle-thumb-background-color);
	}
}

:deep(.thumb-slot) {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: -1px;
	transition:
		left $thumb-transition,
		transform $thumb-transition;
}
</style>
